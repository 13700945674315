<template>
  <div>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <v-container fill-height>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          xs="12"
          sm="8"
          md="4"
          lg="6"
          xl="4"
        >
          <v-fade-transition hide-on-leave>
            <v-skeleton-loader
              v-if="loading"
              height="301"
              class="mx-auto"
              type="image"
            />
          </v-fade-transition>

          <v-fade-transition hide-on-leave>
            <v-card
              v-if="!loading"
              outlined
            >
              <v-row justify="center">
                <v-card-title
                  v-if="isInvalidToken"
                  class="text-center"
                  v-text="$t('verify.invalidToken')"
                />

                <v-card-title
                  v-else
                  class="text-center"
                  v-text="$t('verify.newPwdTitle')"
                />
              </v-row>

              <v-divider />

              <v-card-text>
                <div v-if="isInvalidToken">
                  <v-container>
                    <v-row justify="center">
                      <div v-text="$t('verify.invalidTokenText')" />
                    </v-row>
                  </v-container>

                  <v-divider class="my-2" />

                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn
                        color="primary"
                        to="/signin"
                        v-text="$t('verify.genNewToken')"
                      />
                    </v-row>
                  </v-card-actions>
                </div>

                <div v-else>
                  <v-form ref="form">
                    <v-text-field
                      :disabled="isInvalidToken"
                      v-model="password"
                      :rules="[v => v.length >= 6 || $t('validation.invalidPwd')]"
                      :append-icon="mdiLock"
                      validate-on-blur
                      name="password"
                      :label="$t('verify.newPwdLabel')"
                      type="password"
                      outlined
                      dense
                    />

                    <v-text-field
                      :disabled="isInvalidToken"
                      v-model="passwordConfirm"
                      :rules="[v => v === password || $t('validation.pwdsDontMatch')]"
                      :append-icon="mdiLock"
                      validate-on-blur
                      name="passwordConfirm"
                      :label="$t('verify.newPwdConfirmLabel')"
                      type="password"
                      outlined
                      dense
                    />

                    <v-card-actions>
                      <v-row justify="center">
                        <v-btn
                          color="primary"
                          :loading="loadingBtn"
                          :disabled="isInvalidToken || loading || pwdChanged"
                          @click="submit"
                          v-text=""
                        >
                         {{ $t('verify.pwdConfirm') }}
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </div>
              </v-card-text>
            </v-card>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { SnackBar } from '@components/App'
import API from '@api'
import { mdiLock } from '@mdi/js'

export default {
  components: {
    SnackBar
  },

  async created () {
    /*
    * Token Validation
    */
    const {token} = this.$route.query
    this.token = token

    if (token) {

      try {

        const params = { token }
        const data = await API().get('user/validate/email', {params})

        if (data) {

          this.email = data.email
          this.id = data.user

        } else {

          this.snackMsg = 'expiredPwdToken'
          this.snackOn =  true
          this.isInvalidToken = true

        }

      } catch (err) {

        this.snackMsg = 'errPwdToken'
        this.snackOn = true

      }

    } else {

      this.$router.push({name: 'open.signin'})
      
    }

    this.loading = false
  },

  data () {
    return {
      mdiLock,
      
      snackMsg: '',
      snackOn: false,
      loading: true,
      loadingBtn: false,
      token: '',
      isInvalidToken: false,
      email: '',
      password: '',
      passwordConfirm: '',
      pwdChanged: false
    }
  },
  
  methods: {
    async submit () {
      this.loadingBtn = true

      if (this.$refs.form.validate()) {

        try {

          await API().put('user-reset-pwd', {
            password: this.password 
          },
          {
            params: {
              id: this.id,
              token: this.token
            }
          })

          this.pwdChanged = true

          await API().delete('/user/validate/delete', {
            params: {
              token: this.token
            }
          })

          this.$router.push({name: 'open.signin', params: { snackMsg: 'pwdChanged' }})

        } catch(err) {

          this.snackMsg = err.response.data.errMsg
          this.snackOn = true

        }

      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}

a {
  text-decoration: none;
}
</style>